export function basketToAcceptedBags(basket) {
  const bags = {};
  for (const basket_id in basket) {
    const basketProduct = basket[basket_id];
    const { product_type } = basketProduct;
    if (product_type === 'bag') {
      const { product_details, product_id, quantity } = basketProduct;
      const { passenger_id } = product_details;
      const currentSelections = bags[passenger_id] ?? {};
      currentSelections[product_id] = quantity;
      bags[passenger_id] = currentSelections;
    }
  }
  return bags;
}

/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import MainHeader from '../components/baggage/MainHeader';
import BaggageFooter from '../components/baggage/BaggageFooter';
import JourneySection from '../components/baggage/JourneySection';
import {
  languageConfigurations,
  initializeLocalization,
} from '../translations';
import { withLocalize } from 'react-localize-redux';

import {
  setBaggageData,
  setBaggageBasket,
  setBaggageDisclaimers,
  setLoading,
  isMobile,
  showModal,
  setBaggagePassengers,
  createBasketId,
  setOfferedProducts,
  setLocale,
} from '../redux/actions';
import { setDefaultPassengers } from '../utils/setDefaultPassengers';
import { rtlLangs } from '../translations/rtl-langs';
import { basketToAcceptedBags } from '../utils/basketToAcceptedBags';

const MOBILE_WIDTH_THRESHOLD = 768;

class BaggageWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: typeof window !== 'undefined' ? window.innerWidth : 1200,
    };
    this.state.isMobile = this.state.width <= MOBILE_WIDTH_THRESHOLD;
  }

  getBaggageJson = () => {
    const {
      getBaggageJson,
      onBaggageBasketChange = () => {},
      setBaggageData,
      setBaggagePassengers,
      setBaggageDisclaimers,
      baggageDisclaimers,
      setActiveLanguage,
      existingBasket,
      setBaggageBasket,
      basketUpdated,
      setLocale,
    } = this.props;
    window.onBaggageBasketChange = onBaggageBasketChange;
    getBaggageJson.then((data) => {
      if (!data) return;
      const { passengers = [], language = 'en-US' } = data;
      setDefaultPassengers(passengers, languageConfigurations, language);
      setActiveLanguage(language);
      setLocale(language);
      setBaggageData(data);
      setBaggageDisclaimers(baggageDisclaimers);
      setBaggagePassengers(passengers);
      if (existingBasket && !basketUpdated) {
        setBaggageBasket({ bags: basketToAcceptedBags(existingBasket) });
      }
    });
  };

  componentDidMount() {
    const { initialize, addTranslationForLanguage } = this.props;
    initializeLocalization(initialize, addTranslationForLanguage);
    this.getBaggageJson();
  }

  render() {
    const { modal, locale } = this.props;

    return (
      <div
        style={{ fontFamily: this.props.fontFamily }}
        className={`gr-text-primary-text gr-text-center ${
          modal
            ? 'gordian-modal gr-w-full sm:gr-w-11/12 lg:gr-w-3/5'
            : 'gordian-embedded gr-w-full'
        }`}
        dir={rtlLangs.includes(locale) ? 'rtl' : 'ltr'}
      >
        <br />
        <div>
          <MainHeader theme={this.props.theme} />
        </div>

        <div
          className={`gr-flex-col gr-flex-1 gr-overflow-auto ${
            modal ? 'gr-h-modal' : 'gr-h-embedded'
          }`}
        >
          <JourneySection />
        </div>

        <BaggageFooter />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  basket: state.basket,
  baggageData: state.baggageJSON.data,
  products: state.products.products,
  passengers: state.passengers.listOfBaggagePassengers,
  isMobile: state.session.mobile,
  theme: state.session.theme,
  exitModal: state.session.exitModal,
  basketUpdated: state.session.basketUpdated,
  locale: state.session.locale,
});

const ModalLocalize = withLocalize(BaggageWidget);

export default connect(mapStateToProps, {
  setBaggageData,
  setOfferedProducts,
  createBasketId,
  setBaggagePassengers,
  setBaggageDisclaimers,
  setLoading,
  isMobile,
  setBaggageBasket,
  showModal,
  setLocale,
})(ModalLocalize);

import React, { useEffect, useState, useLayoutEffect } from 'react';
import { withLocalize } from 'react-localize-redux';
import { initializeLocalization } from '../translations';
import { connect, useDispatch } from 'react-redux';
import {
  createBasketId,
  isMobile,
  setBaggageData,
  setOfferedProducts,
  setPassengers,
  setCurrentBaggageJourney,
  setBaggageJourneys,
  setBaggageDisclaimers,
  setMaxProductPrice,
  setCurrency,
  setVariants,
  setFontFamily,
  showModal,
  setBaggageBasket,
} from '../redux/actions';
import BaggageHeader from '../components/baggage2/BaggageHeader';
import FlightSelector from '../components/baggage2/FlightSelector';
import BaggageFooter from '../components/baggage2/BaggageFooter';
import { DesktopBagSummaries } from '../components/baggage2/DesktopBagSummaries';
import BagsContainer from '../components/baggage2/BagsContainer';
import { ClipLoader } from 'react-spinners';
import { parseItinerary } from '../utils/parseItinerary';
import { FlightTransitionModal } from '../components/baggage2/FlightTransitionModal';
import { Backdrop } from '../components/baggage2/Backdrop';
import { basketToAcceptedBags } from '../utils/basketToAcceptedBags';

function getJourneysFromBags(bags) {
  const bagList = Object.values(bags);
  const journeys = [];

  for (const bag of bagList) {
    const itinerary = bag.product_details.flight_string;
    const parsedItinerary = parseItinerary(itinerary);

    const from = parsedItinerary[0].from;
    const to = parsedItinerary[parsedItinerary.length - 1].to;
    const departureDateTime = parsedItinerary[0].departureDatetime;

    if (!journeys.find((j) => j.from === from && j.to === to)) {
      const newJourney = {
        flights: parsedItinerary,
        from,
        to,
        departureDateTime,
      };
      journeys.push(newJourney);
    }
  }

  const orderedJourneys = journeys.sort(
    (j1, j2) => new Date(j2.departureDatetime) - new Date(j1.departureDatetime)
  );

  if (orderedJourneys.length === 2) {
    orderedJourneys[0].direction = 'Outbound';
    orderedJourneys[1].direction = 'Inbound';
  }

  orderedJourneys.forEach((j) => {
    if (j.from === j.to) {
      j.direction = 'Roundtrip';
    }
  });

  orderedJourneys[orderedJourneys.length - 1].isLastJourney = true;

  return orderedJourneys;
}

function getJourneysFromItineraries(itineraries, bags) {
  const arraysAreEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
      return false;
    }

    const sortedArr1 = arr1.sort();
    const sortedArr2 = arr2.sort();

    for (let i = 0; i < sortedArr1.length; i++) {
      if (sortedArr1[i] !== sortedArr2[i]) {
        return false;
      }
    }

    return true;
  };

  const bagList = Object.values(bags);
  const bookingJourneys = itineraries.flatMap((i) => i.journeys);

  const journeys = [];

  for (const bag of bagList) {
    const bagJourneys = (
      bag.product_details.journey_ids ?? [bag.product_details.journey_id]
    )
      .map((journeyId) =>
        bookingJourneys.find((j) => j.journey_id === journeyId)
      )
      .sort((j1, j2) => {
        const segments1 = j1.segments.sort(
          (s1, s2) => new Date(s2.departure_time) - new Date(s1.departure_time)
        );
        const segments2 = j2.segments.sort(
          (s1, s2) => new Date(s2.departure_time) - new Date(s1.departure_time)
        );

        return (
          new Date(segments2[0].departure_time) -
          new Date(segments1[0].departure_time)
        );
      });

    const journey = {};
    for (const [i, bagJourney] of bagJourneys.entries()) {
      const journeySegments = bagJourney.segments
        .map((s) => ({
          from: s.departure_airport,
          to: s.arrival_airport,
          departureDateTime: s.departure_time,
        }))
        .sort(
          (f1, f2) =>
            new Date(f2.departureDatetime) - new Date(f1.departureDatetime)
        );

      if (i === 0) {
        journey['from'] = journeySegments[0].from;
        journey['departureDateTime'] = journeySegments[0].departureDateTime;
      }
      journey['to'] = journeySegments[journeySegments.length - 1].to;
      journey['flights'] = journey['flights']
        ? journey['flights'].concat(journeySegments)
        : journeySegments;
      journey['journey_ids'] = journey['journey_ids']
        ? journey['journey_ids'].concat(bagJourney.journey_id)
        : [bagJourney.journey_id];
    }

    if (
      !journeys.find((j) => arraysAreEqual(j.journey_ids, journey.journey_ids))
    ) {
      journeys.push(journey);
    }
  }

  if (journeys.length === 2) {
    journeys[0].direction = 'Outbound';
    journeys[1].direction = 'Inbound';
  }

  journeys.forEach((j) => {
    if (j.from === j.to) {
      j.direction = 'Roundtrip';
    }
  });

  journeys[journeys.length - 1].isLastJourney = true;

  return journeys;
}

function BaggageWidget2(props) {
  const {
    baggageData,
    basket,
    journeys,
    passengers,
    activeJourney,
    fontFamily,
    modal,
    variants,
    translate,
  } = props;

  const MOBILE_WIDTH_THRESHOLD = 650;

  const dispatch = useDispatch();
  const [showFlightTransitionModal, setShowFlightTransitionModal] =
    useState(false);

  function useWindowWidth() {
    const [width, setWidth] = useState(window.innerWidth);
    useLayoutEffect(() => {
      function updateWidth() {
        setWidth(window.innerWidth);
      }

      let enableCall = true;
      window.addEventListener('resize', () => {
        if (!enableCall) return;
        enableCall = false;
        updateWidth();
        setTimeout(() => (enableCall = true), 300);
      });
      updateWidth();
      return () => window.removeEventListener('resize', updateWidth);
    }, []);
    return width;
  }
  const width = useWindowWidth();

  const isMobile = width <= MOBILE_WIDTH_THRESHOLD;

  const bagShouldBeDisplayed = (bag) => {
    if (bag.product_details.journey_ids) {
      return bag.product_details.journey_ids.some((journeyId) =>
        activeJourney.journey_ids.includes(journeyId)
      );
    } else if (bag.product_details.journey_id) {
      return activeJourney.journey_ids.includes(bag.product_details.journey_id);
    } else {
      const itinerary = parseItinerary(bag.product_details.flight_string);
      return (
        itinerary[0].from === activeJourney.from &&
        itinerary[itinerary.length - 1].to === activeJourney.to
      );
    }
  };

  const bagsToDisplay = activeJourney
    ? Object.values(baggageData?.results?.products?.bag ?? {}).filter((bag) =>
        bagShouldBeDisplayed(bag)
      )
    : [];

  const getBaggageJson = () => {
    const {
      getBaggageJson,
      baggageDisclaimers,
      onBaggageBasketChange = () => {},
      setBaggageData,
      createBasketId,
      fontFamily,
      existingBasket,
      basketUpdated,
      setPassengers,
      setOfferedProducts,
      setActiveLanguage,
      setCurrentBaggageJourney,
      setBaggageJourneys,
      setBaggageDisclaimers,
      setBaggageBasket,
      setMaxProductPrice,
      setCurrency,
      setFontFamily,
      variants = {},
    } = props;
    window.onBaggageBasketChange = onBaggageBasketChange;
    getBaggageJson.then((data) => {
      const { booking_id, language = 'en-US' } = data;
      const { products = {}, itineraries } = data.results;

      const booleanizedVariants = {};
      Object.entries(variants).forEach((v) => {
        booleanizedVariants[v[0]] =
          v[1] === 'true' || v[1] === true
            ? true
            : v[1] === 'false' || !v[1]
              ? false
              : v[1];
      });
      dispatch(setVariants(booleanizedVariants));
      setBaggageData(data);
      setActiveLanguage(language);
      createBasketId(booking_id);
      setPassengers(data.passengers ?? data.results?.passengers ?? []);
      setOfferedProducts(products);

      const journeys = itineraries
        ? getJourneysFromItineraries(itineraries, products.bag)
        : getJourneysFromBags(products.bag);

      setBaggageJourneys(journeys);
      setCurrentBaggageJourney(journeys[0]);
      setBaggageDisclaimers(baggageDisclaimers);

      setFontFamily(fontFamily);

      const allBags = Object.values(products.bag ?? {});

      const maximumPrice = allBags
        .flatMap((bag) => {
          return Object.values(bag.price_and_availability).flat();
        })
        .map((option) => option.price.total)
        .sort((p1, p2) => p2.amount - p1.amount)[0];
      setMaxProductPrice(maximumPrice);
      setCurrency(maximumPrice.currency);

      if (existingBasket && !basketUpdated) {
        setBaggageBasket({ bags: basketToAcceptedBags(existingBasket) });
      }
    });
  };

  const buildBasket = () => {
    const currentBasket = basket.bags;
    const basketToReturn = [];

    for (const paxId in currentBasket) {
      const passengerSelections = currentBasket[paxId];
      const baggageProducts = baggageData.results.products.bag;

      for (const productId in passengerSelections) {
        const selection = passengerSelections[productId];
        const product = baggageProducts[productId];
        const details = product['product_details'];
        const item = {
          passenger_id: paxId,
          price: selection['totalPrice'],
          display_name: details['display_name'],
          quantity: selection['quantity'],
          product_type: 'bag',
          checked: details['checked'],
          product_id: product['product_id'],
          ...(product['session_id'] && { session_id: product['session_id'] }),
          ...(selection['uniqueId'] && { unique_id: selection['uniqueId'] }),
          ...(product['carrier'] && { carrier: product['carrier'] }),
        };

        basketToReturn.push(item);
      }
    }
    return basketToReturn;
  };

  const selectedBags = Object.values(basket?.bags ?? {}).flatMap((b) =>
    Object.keys(b)
  );
  const noBagsSelected = !bagsToDisplay.some((b) =>
    selectedBags.includes(b.product_id)
  );

  const onNextClick = () => {
    if (
      variants.test_baggage_on_exit_modal &&
      !showFlightTransitionModal &&
      noBagsSelected
    ) {
      setShowFlightTransitionModal(true);
    } else {
      setShowFlightTransitionModal(false);
      if (activeJourney.isLastJourney) {
        const confirmedBasket = buildBasket();
        window.onBaggageBasketChange(confirmedBasket);
        console.log('Confirmed basket', confirmedBasket);
        dispatch(showModal(false));
      } else {
        const activeJourneyIndex = journeys.findIndex(
          (j) => j.from === activeJourney.from && j.to === activeJourney.to
        );
        dispatch(setCurrentBaggageJourney(journeys[activeJourneyIndex + 1]));
      }
    }
  };

  useEffect(() => {
    const { initialize, addTranslationForLanguage } = props;
    initializeLocalization(initialize, addTranslationForLanguage);
    getBaggageJson();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      id={'baggage-widget'}
      style={{
        fontFamily,
        height: isMobile ? 'fit-content' : 'none',
      }}
      className={`gr-flex gr-flex-col ${
        !showFlightTransitionModal
          ? 'gr-border gr-border-solid gr-border-gray-300'
          : ''
      } gr-rounded-lg ${modal ? 'gordian-modal' : 'gordian-embedded'} ${
        isMobile || !modal ? 'gr-h-full gr-w-full' : 'gr-w-11/12'
      }`}
    >
      {baggageData?.results && activeJourney ? (
        <>
          <BaggageHeader isMobile={isMobile} />
          <FlightSelector isMobile={isMobile} />
          <div
            id={'baggage-scrollable-section'}
            className="gr-overflow-y-auto gr-overflow-x-hidden gr-h-full"
          >
            {isMobile ? null : (
              <DesktopBagSummaries bagsToDisplay={bagsToDisplay} />
            )}
            <BagsContainer
              bagsToDisplay={bagsToDisplay}
              passengers={
                passengers?.listOfPassenger ?? baggageData?.results.passengers
              }
              isMobile={isMobile}
              translate={translate}
            />
          </div>
          {showFlightTransitionModal ? (
            <>
              <FlightTransitionModal
                setShowFlightTransitionModal={setShowFlightTransitionModal}
                onNextClick={onNextClick}
              />
              <Backdrop />
            </>
          ) : null}
          <BaggageFooter
            baggageData={baggageData}
            isMobile={isMobile}
            onNextClick={onNextClick}
          />
        </>
      ) : (
        <>
          <button />
          <ClipLoader />
        </>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  basket: state.basket,
  baggageData: state.baggageJSON.data,
  journeys: state.itinerary.baggageJourneys,
  products: state.products.products,
  passengers: state.passengers,
  isMobile: state.session.mobile,
  theme: state.session.theme,
  exitModal: state.session.exitModal,
  basketUpdated: state.session.basketUpdated,
  activeJourney: state.itinerary.currentBaggageJourney,
  ...(state.session.variants &&
    Object.keys(state.session.variants).length > 0 && {
      variants: state.session.variants,
    }),
});

const ModalLocalize = withLocalize(BaggageWidget2);

export default connect(mapStateToProps, {
  setBaggageData,
  setBaggageBasket,
  setOfferedProducts,
  createBasketId,
  setPassengers,
  setCurrentBaggageJourney,
  setBaggageJourneys,
  setBaggageDisclaimers,
  setMaxProductPrice,
  setCurrency,
  setVariants,
  setFontFamily,
  isMobile,
})(ModalLocalize);
